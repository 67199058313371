<template>
  <p v-if="navTreeList.length>1" style="margin-bottom:15px;">
    <el-button
      type="text"
      v-for="(item,i) in navTreeList"
      :key="i"
      class="self_btn"
      @click="lookChildList2(item,i)"
      :style="{color:i==(navTreeList.length-1)?'#333333':'#999999'}"
    >
      <i v-if="i>0" class="el-icon-arrow-right el-icon--left"></i>
      {{item.name}}
    </el-button>
  </p>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
export default {
  props:{
    crumbList:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      navTreeList:this.crumbList
      // [
      //   {
      //     path:'/xx',
      //     name:'xx页'
      //   }
      // ]
    }
  },
  computed:{
    ...mapGetters(['getCrumbNavList']),

  },

  watch:{
    crumbList(n,o){
      this.navTreeList = n;
    }
  },
  methods:{
    ...mapActions(['storeCrumbNavList']),
    lookChildList(obj){
      const {LectureID,LectureName} = obj;
      let obj1 = {
        LectureID,
        LectureName
      }
      let exist = this.navTreeList.some(item=>{
        return LectureID=='0'||LectureID==item.LectureID;
      })
      if(!exist){
        this.navTreeList.push(obj1)
      }
      this.PLectureID = LectureID
      const { current, pageSize } = this.pagination;
      this.getList(current, pageSize, this.searchModel,this.PLectureID);
    },
    lookChildList2(obj,i){
      let num = i - (this.navTreeList.length-1)
      this.$router.go(num)
    },
  }
}
</script>
<style lang='less' scoped>
.self_btn{
  padding-top:0;
  padding-bottom:0!important;
  vertical-align:top;
  // color:#999999!important
}
</style>
