<template>
  <div id="app" class="wh100 app" :style="{minHeight}">
    <router-view />
  </div>
</template>
<script>
export default{
  data(){
    return {
      refreshTimer:null,
      minHeight:'800px'
    }
  },
  watch:{
    '$route':{
      handler(n,o){
        console.log('route watch：', o?o.path:'', '->', n?n.path:'');
        const {path,name} = n;
        // 进入直播间要保持登录有效时长 刷新cookie
        this.checkOutOfDate2(path)
        this.minHeight = path.indexOf('liveStudioSmall')>-1?'720px':'800px'
      },
      immediate:true
    }
  },
  methods:{
    // 进入首页 暂时随便调一个接口 检测系统登录超时
    checkOutOfDate(){
      const apiName='UserEventLog';
      let data = {
        EventTs:0
      }
      this.$api[apiName](data).then(res=>{
      }).catch(err=>{
        console.log(err)
      });
    },

    checkOutOfDate2(path){
      // '/liveStudio' and '/liveStudioSmall'
      if(path.indexOf('/liveStudio')>-1){
        if(!this.refreshTimer){
          this.refreshTimer = setInterval(() => {
            this.checkOutOfDate()
          }, 1000*90);
        }
      }else if(path.indexOf('/videoManage') > -1){
        /** 视频上传时需要很长时间，需要进行会话保持。 */
        if(!this.refreshTimer){
          this.refreshTimer = setInterval(() => {
            console.log('videoManage keep alive.');
            this.checkOutOfDate()
          }, 1000*300);
        }
      }
      else{
        if(this.refreshTimer){
          clearInterval(this.refreshTimer);
          this.refreshTimer = null;
        }
      }
    },
  },
  mounted(){}
}
</script>

<style lang="less">
@import '~@/assets/style/self-theme-color.less';
html {
  height: 100%;
  line-height: 1!important;
}
p,
ul,
li,
body {
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}
body {
  height: 100%;
  font-size: 16px;
  overflow: auto;
  min-height: 100%;
  min-width: 1024px;
  font-family: Source Han Sans CN, PingFangSC-Light, 'PingFang SC',
    'PingFangSC-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-weight: normal;
}
#app {
  color: #333333;
}
.wh100 {
  width: 100%;
  height: 100%;
}
.bg_white{
  background:#ffffff;
}
.bg_gray{
  background:#eeeeee;
}

.ft12 {
  font-size: 12px;
}
.ft14 {
  font-size: 14px;
}

.ft16 {
  font-size: 16px;
}
.ft18 {
  font-size: 18px;
}
.ft20 {
  font-size: 20px;
}
.ft22 {
  font-size: 22px;
}
.ft24 {
  font-size: 24px;
}
.ft26 {
  font-size: 26px;
}
.ft28 {
  font-size: 28px;
}
.ft30 {
  font-size: 30px;
}
.ft32 {
  font-size: 32px;
}
.ft40 {
  font-size: 40px;
}

.letter2{
  letter-spacing: 4px;
}
.letter1{
  letter-spacing: 1px;
}

.theme_color{
  color:#3870F6;
}
.theme_bg_color{
  background-color:#3870F6;
}
.colorf {
  color: #ffffff;
}
.color3 {
  color: #333333;
}
.color6 {
  color: #666666;
}
.color9 {
  color: #999999;
}
.colory{
  color:#FFA837;
}
.colorb{
  color:#0Fb2F6;
}
.green{
  color:#19CF8A;
}
.red{
  color:@red;
}

.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.tc {
  text-align: center;
}
.radius16{
  border-radius:16px;
}
.radius6{
  border-radius:6px;
}

.pointer {
  cursor: pointer;
}

.font-text{
  font-family: PingFangSC-Regular,'Source Han Sans CN','Avenir', Helvetica, Arial, sans-serif;
}

.default_bg {
  background: url('~@/assets/default_avatar.png') left top no-repeat;
  background-size: 100%;
}

// change elementui css
.el-button--primary{
  background:@blue!important;
  border-color:@blue!important;
  color:#ffffff!important;
}
.el-button--warning{
  background:@yellow!important;
  border-color:@yellow!important;
  color:#ffffff!important;
}
.el-button--success{
  background:@green!important;
  border-color:@green!important;
  color:#ffffff!important;
}
.el-button--text{
  color:@blue;
}
.plain1.el-button--small.is-plain{
   border-color:@blue!important;
   color:@blue!important;
}
// .el-table--small{
//   font-size: 14px;
// }
.el-button--small{
  font-size: 14px;
}
.el-input__inner{
  border-color:#D9D9D9;
}
.el-input--small .el-input__inner{
  // height: 36px!important;
  line-height: 36px!important;
  font-size: 14px!important;
}


.switchbar{
  .el-tabs__item{
    padding:0 9px!important;
    height: 35px;
    line-height: 35px;
  }
  .el-tabs__item.is-active{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: @blue;
    color:white;
  }
  .el-tabs__nav-wrap:after{
    height: 1px;
    background: @blue;
  }
  .el-tabs__active-bar.is-top{
    display: none;
  }
}

.dg_title_css{
  .el-dialog__header{
    padding:0;
    .dg_title{
      height: 50px;
      line-height: 50px;
      background-color:#F2F2F2;
      text-align: center;
    }
    .el-dialog__headerbtn{
      top:16px;
    }

  }
  .el-form-item--mini .el-form-item__content{
    line-height: 36px;
  }
  .el-form-item--mini .el-form-item__label{
    line-height: 36px;
  }
  .el-input__inner,.el-form-item--mini .el-form-item__content{
    height: 36px;
    line-height: 36px;
  }
  .upload-demo .el-upload{
    width:100%;
  }
}

.dg_title_css,.dg_title_css_add_stu{
  position: absolute;
  // top: 50%;
  top: 46%;
	left: 50%;
  transform: translate(-50%,-50%);

  border-radius: 6px;
  overflow: hidden;
}


.el-table{
  border: 1px solid #e9e9e9;
  border-bottom: 0;
  border-radius: 6px;
}
// .el-table th{
//   height: 50px;
// }
// .el-table td{
//   padding:7px 0;
// }
body .el-table th.gutter{
  display: table-cell!important;
  background: #F9FBFC;
}
.el-loading-spinner i{
  color: @blue;
}
.el-table__fixed-right-patch{
  background: #F9FBFC;
}
.el-pager .number.active{
  background-color:@blue!important;
}
.el-radio__input.is-checked .el-radio__inner{
  background-color:@blue;
}
.el-radio__input.is-checked+.el-radio__label {
  color: @blue;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: @blue!important;
  border-color: @blue!important;
}
.el-select-dropdown__item.selected{
  color:@blue!important;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: @blue;
}
.el-step__head.is-finish,.el-step__title.is-finish {
  color: @blue;
}
.el-step__head.is-finish .el-step__line{
  border:0;
  background-color: @blue!important;
}
.el-date-table td.end-date span, .el-date-table td.start-date span {
    background-color: @blue!important;
}
.el-date-table td.today span {
    color: @blue!important;
    font-weight: 700;
}
.el-date-table td.today.start-date span {
    color: white!important;
    font-weight: 700;
}
.el-date-table td.today.end-date span {
    color: white!important;
    font-weight: 700;
}
.form_dg_date_time{
  .el-date-table td.current:not(.disabled) span{
    color: white!important;
    background-color: @blue!important;
  }
  // .el-date-table td.today span{
  //   color: white!important;
  //   background-color: @blue!important;
  // }
}

.el-picker-panel__footer .el-picker-panel__link-btn.is-plain:hover{
  color: @blue!important;
  border-color: @blue!important;
}
.el-time-panel__btn.confirm{
  color: @blue!important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.disabledUpload .el-upload--picture-card {
  display: none!important;
}
.search_btn_font{
  font-size: 14px;
  padding:8px 15px;
}

// .sidebar::-webkit-scrollbar{
//   width:12px;
//   height:10px;
// }
::-webkit-scrollbar{
  width:12px;
  height:10px;
}
::-webkit-scrollbar-thumb{
  height:150px;
  background-color:#e4e8ec;  //696A8C
  border-radius:7px;
  -webkit-box-shadow: 1px 3px 6px rgba(0, 0, 0, .1);
}
::-webkit-scrollbar-thumb:hover {
  background-color:#e4e8ec; // rgba(17, 68, 116, 0.67);
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #f5f6f8;
}
.scroll_bar_width::-webkit-scrollbar{
  width:10px;
}

.app::-webkit-scrollbar-thumb{
  background-color:rgba(200,200,200,0.4);  //696A8C
}
.app::-webkit-scrollbar-track{
  background-color:rgba(180, 180, 180, 0.2);  //696A8C
}
.text_elip{
  max-width: calc(100% - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.one_elip{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// 上传文件的图标 单独改了一下
.upload_icon_bg_cicle{
  display:inline-block;
  width: 44px;
  height:44px;
  line-height: 44px;
  border-radius:50%;
  color:#ffffff;
  background:#3870F6;
}
.live_tip{
  top:15%!important;
}

// .el-table .el-table__row .cell{
//   position:relative!important;
// }
.model_top_switch{
  position: absolute;
  width: 40px;
  height: 30px;
  left: 10px;
  // bottom:0;
}
.align_mid{
  vertical-align: middle;
}
.flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
