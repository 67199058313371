<template>
<div>
  <el-menu
    :default-active="activeIndex"
    class="menu el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    @select="menuSelect"
    background-color="transparent"
    text-color="#fff"
    active-text-color="#3870F6"
    router
  >
  <!-- :default-openeds="[this.$route.path]" -->
    <template v-for="(item,i) in menuArr">
      <el-menu-item
        v-if="!item.children"
        :index="item.route"
        :key="i"
        :route="item.route"
      >
        <i :class="item.icon" class="colorf " style="font-size:18px;"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>

      <el-submenu v-else :index="i+1" :key="i">
        <template slot="title">
          <i :class="item.icon" class="colorf"></i>
          <span>{{item.name}}</span>
        </template>
        <el-menu-item-group >
          <el-menu-item
            v-for="(item2,i2) in item.children"
            :key="i2"
            :index="item2.route"
            :route="item2.route"
          >
            <span class="dot ft20">·</span>
            <span style="display:inline-block;">{{item2.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </template>
  </el-menu>
</div>
</template>
<script>
import { menuArr } from './menu.js';
export default {
  data() {
    return {
      menuArr:location.origin.indexOf('192.168')>-1?menuArr:window.sysType==2?[menuArr[0]]:menuArr.slice(1),
      // menuArr,
      activeIndex:'',
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    menuSelect(index,indexPath){
      console.log(indexPath)
      this.$emit('menuSelect',indexPath)
    },
  },
  mounted() {
    this.activeIndex = this.$route.path;
  }
}
</script>
<style lang='less' scoped>
.menu{
  border-right: 0;
  .el-menu-item{
    border-left: 2px solid #292A3C;
    font-size: 16px;
    &:hover{
      background-color:#393B4A!important;
    }
  }

  .el-submenu__title,.el-menu-item{
    i{
      margin-right: 8px;
      color:#ffffff;
    }
  }
  .el-menu-item.is-active{
    background:#393B4A!important;
    border-left: 2px solid #3870F6;
    i{
      color:#3870F6;
    }

  }

}

.dot{
  font-weight:550;
  margin-right:8px;
  vertical-align: top;
}

</style>
<style lang="less">
.menu .el-submenu .el-submenu__title{
  font-size: 16px;
  &:hover{
    background-color:#393B4A!important;
  }
}
.menu .el-submenu__icon-arrow{
  font-size: 16px;
}
</style>
