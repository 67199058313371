import Vue from 'vue';
import App from './App.vue';
import './utils/sysType.js';
import router from './router';
import store from './store';
import api from './api';
import utils from './utils'
import pinyinStr from './utils/pinyin'
import InitComponent from './components';

// import '@/utils/fitScreen.js';

import 'normalize.css/normalize.css';
import '@/assets/font/iconfont.css';

import VueClipboard from 'vue-clipboard2'


Vue.use(InitComponent);
Vue.use(api);
Vue.use(utils);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.prototype.$dpcp = (data)=>JSON.parse(JSON.stringify(data))
Vue.prototype.$py = pinyinStr;

Vue.prototype.$isLivePage = ()=>location.href.indexOf('liveStudio')>-1;
Vue.prototype.$tipOk = (msg,duration,customClass)=>Vue.prototype.$message({
  message:msg,
  type:'success',
  customClass:Vue.prototype.$isLivePage()?'live_tip':customClass,
  duration:duration||2500
})

Vue.prototype.$tipInfo = (msg,duration,customClass)=>Vue.prototype.$message({
  message:msg,
  type:'info',
  customClass:Vue.prototype.$isLivePage()?'live_tip':customClass,
  duration:duration||2500
})

Vue.prototype.$tipWarn = (msg,duration,customClass)=>Vue.prototype.$message({
  customClass:Vue.prototype.$isLivePage()?'live_tip':customClass,//'article_msg',
  type:'warning',
  message:msg,
  duration:duration||3000,
});

Vue.prototype.$tipDefault = (msg)=>Vue.prototype.$message({
  message:msg,
  duration:2500
})

Vue.prototype.$sysType=window.sysType;
// example 2020-12-18 13:55:45.000 去掉 .000
Vue.prototype.$formTimeStr = (str)=>str.split('.')[0]
// example 2020-12-18 13:55:45.000 去掉 :45.000
Vue.prototype.$formatTimeStr2 = (str)=>{
  let arr = str.split('.')[0].split(':');
  arr.pop()
  return arr.join(':')
}
// example 2020-12-18 13:55:45.000 -->2020/12/18 13:55:45
Vue.prototype.$formatTimeStr3 = (str)=>{
  return str.replace(/-/g,'/').split('.')[0]
}
Vue.prototype.$reloadLoginUrl = location.href.split('#')[0]+'#/login';
Vue.config.productionTip = false;
let teachArr = [
  '/login',
  '/',
  '/findPass', //找回密码成功直接跳登录页，用不上找回成功提示页
  '/myClass',
  '/myClassDetail',
  '/liveStudio',
  '/liveStudioSmall',
  '/liveStudioDouble'
];

window.addEventListener('storage', function(e){
  //console.log('storage event:', e);
  store.commit('refreshUserInfo');
})

router.beforeEach((to, from, next) => {
  const userInfo = store.getters.getUserInfo;
  if(Object.keys(userInfo).length && to.path=='/login'){
    // 登录成功后强制login页面跳转到dashboard页面
    next('/dashboard');
  }else if(to.path!='/login' && !Object.keys(userInfo).length && to.path.indexOf('findPass')<0){
    next('/login')
    Vue.prototype.$message({
      message:'您还未登录系统，请您先登录。',
      duration:2500,
    })
  }else{
    if(window.sysType==2){
      // 教师直播授课端
      if(teachArr.includes(to.path)){
        next()
      }else{
        next('/myClass')
      }
    }else{
      next();
    }
  }
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
