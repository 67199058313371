import pinyin from 'pinyin';
const sortedData = (data)=>data.sort(pinyin.compare);
const getFirstLetter=(str)=>pinyin(str,{
  style:pinyin.STYLE_FIRST_LETTER
})
export default{
  pinyin,
  sortedData,
  getFirstLetter
}


