/**
 * 导航栏信息基本定义
 * 注意：数据的定义存放位置（数组下标）与listTreeIdx存在关联
 * 根据当前的路由 在这里进行过滤 得到当前面包屑数组
 */
let crumbList = [
  {
    path:'/dashboard',
    name:'首页',
    children:[],
  },
  {
    path:'/studentManage',
    name:'学生管理',
    children:[
      {
        path:'/stuDetail',
        name:'学生信息',
        // listTreeIdx 是树形结构的节点 每一级的 index拼成的字符串，过滤面包屑数组用
        listTreeIdx:'10',
        children:[
          {
            path:'/liveLearnRecord',
            name:'学习记录',
            listTreeIdx:'100',
          }
        ]
      }
    ],
  },
  {
    path:'/classManage',
    name:'录播管理',
    children:[
      {
        path:'/classTimesManage',
        name:'讲次管理',
        listTreeIdx:'20',
        children:[
          {
            path:'/videoManage',
            name:'视频管理',
            listTreeIdx:'200'
          }
        ]
      },
      {
        path:'/classDetail',
        name:'班级详情',
        listTreeIdx:'21',
        children:[
          {
            path:'/liveLearnRecord',
            name:'学习记录',
            listTreeIdx:'210',
          }
        ]
      },
    ],
  },
  {
    path:'/liveVideoManage',
    name:'直播管理',
    children:[
      {
        path:'/coursewareManage',
        name:'课件管理',
        listTreeIdx:'30',
      },
      {
        path:'/liveClassTimesManage',
        name:'讲次管理',
        listTreeIdx:'31',
      },
      {
        path:'/liveClassDetail',
        name:'班级详情',
        listTreeIdx:'32',
        children:[
          {
            path:'/liveLearnRecord',
            name:'学习记录',
            listTreeIdx:'320',
          }
        ]
      },
    ],
  }, 
  {
    path:'/staffManage',
    name:'员工管理',
    children:[
      {
        path:'/addStaff',
        name:'新增员工',
        listTreeIdx:'40',
      },
      {
        path:'/editStaff',
        name:'编辑员工',
        listTreeIdx:'41',
      },
      {
        path:'/staffDetail',
        name:'员工信息',
        listTreeIdx:'42',
      }
    ],
  },
  {
    path:'/quizBaseManage',
    name:'题库管理',
    children:[
      {
        path: '/quizBaseManage',
        name: '题目管理',
        listTreeIdx: '50',
      },
      {
        path: '/examMgr',
        name: '试卷管理',
        listTreeIdx: '51',
        children:[
          {
            path:'/previewExamPaper',
            name:'试卷预览',
            listTreeIdx:'510',
          },
          {
            path:'/listExamRecords',
            name:'考试记录',
            listTreeIdx:'511',
          },
          {
            path:'/previewAnswerPaper',
            name:'答卷预览',
            listTreeIdx:'512',
          }
        ]
      }
    ],
  }, 
  {
    path:'/coInfo',
    name:'单位信息',
  },
  {
    path:'/sysCfg',
    name:'系统设置',
  },  
  {
    path:'/sysLog',
    name:'系统日志',
  },
  {
    path:'/editAccount',
    name:'修改个人资料',
  },
  {
    path:'/accountCertify',
    name:'账号认证',
  },
  {
    path:'/editPass',
    name:'修改密码',
  }
];

let flatArr = [];
/** 将不同深度的路径信息变为单一深度信息(便于查找) */
function childrenDiGui(arr){
  arr.forEach(item=>{
    flatArr.push({
      path:item.path,
      name:item.name,
      listTreeIdx:item.listTreeIdx||''
    })
    if(item.children&&item.children.length){
      childrenDiGui(item.children)
    }else{
      // nothing
    }
  })
}

childrenDiGui(crumbList);
//console.log(flatArr,'flatArr')

/** 根据页面目标路径获取完整导航栏信息 */
function filterCrumbList(routePath,lastPath){
  //console.log('filterCrumbList: from ', lastPath, ' to ', routePath);

  /** 查找目标路径的配置信息 */
  let arr = flatArr.filter((item,i)=>{
    if(routePath=='/liveLearnRecord'){
      let flag = (item.path==routePath)&&((i>0)&&flatArr[i-1].path == lastPath);
      return flag;
    }else{
      return item.path==routePath;
    }
  })
  //console.log('filterCrumbList arr:', arr);

  /** 根据目标路径的配置信息为其分解出完整路径索引 */
  let treeIndexArr = [];
  if(arr.length&&arr[0].listTreeIdx.length){
    treeIndexArr = arr[0].listTreeIdx.split('');
  }

  /** 根据路径索引获取整个路径信息 */
  //console.log('filterCrumbList treeIndexArr:', treeIndexArr)
  let currentCrumbList = [];
  treeIndexArr.forEach((item2,i2)=>{
    let val = {},obj = {};
    switch(i2){
      case 0:
        obj = crumbList[Number(item2)]
        break;
      case 1:
        obj = crumbList[treeIndexArr[0]].children[Number(item2)];
        break;
      case 2:
        obj = crumbList[treeIndexArr[0]].children[treeIndexArr[1]].children[Number(item2)];
        break;
    }
    val = {
      path:obj.path,
      name:obj.name
    }

    currentCrumbList.push(val);
  })

  return currentCrumbList;

}

export default filterCrumbList
