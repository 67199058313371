<template>
  <el-form :inline="true" :model="formInline" class="searchbar demo-form-inline">
    <el-form-item
      v-for="(item,i) in searchItems"
      :key="i"
    >
      <template v-if="item.type=='input'">
        <el-input
          v-model="formInline[item.keyName]"
          :placeholder="item.placeholder"
        ></el-input>
      </template>

      <template v-else-if="item.type=='searchInput'">
        <el-input v-model="formInline[item.keyName]" :placeholder="item.placeholder" style="width:260px;" @change="search">
          <el-button
            slot="append"
            type="primary"
            class="search_btn search_btn2"
            icon="iconfont icon-sousuo"
            @click="search"
          ></el-button>
        </el-input>
      </template>

      <template v-else-if="item.type=='select'">
        <el-select
          v-model="formInline[item.keyName]"
          @change="search(item)"
          :placeholder="item.placeholder"
          style="width:180px;"
          clearable
        >
          <el-option
            v-for="(select,i2) in item.selectors"
            :key="i2"
            :label="select.label"
            :value="select.val"
          ></el-option>
        </el-select>
      </template>

      <template v-else-if="item.type=='date-time-range'">
        <el-date-picker
          v-model="formInline[item.keyName]"
          type="datetimerange"
          range-separator="-"
          start-placeholder="请选择开始时间"
          end-placeholder="请选择结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width:100%;"
          @change="search"
        ></el-date-picker>
      </template>

      <template v-else-if="item.type=='date'">
        <el-date-picker
          v-model="formInline[item.keyName]"
          type="date"
          :placeholder="item.placeholder"
          value-format="yyyy-MM-dd"
          style="width:100%;"
          @change="search"
        ></el-date-picker>
      </template>

      <template v-else-if="item.type=='btn'">
        <el-button class="search_btn_font" type="primary" @click="search" style="height:36px;">查询</el-button>
      </template>
    </el-form-item>
    <slot name="btns"></slot>
  </el-form>

</template>

<script>
  export default {
    props:{
      searchItemsIn:Array
    },

    data(){
      return {
        formInline:{},
        classModelSelectors:[],
        classCourseSelectors:[],
      }
    },

    mounted(){
      this.searchItems = this.$dpcp(this.searchItemsIn)
      //console.log('ListSearchbar mounted',this.searchItems);
      this.searchItems.forEach(item=>{
        if(item.type=='date-time-range'){
          this.$set(this.formInline,item.keyName,[])
          // this.formInline[item.keyName]=[]
        }else{
          this.$set(this.formInline, item.keyName, '')
          // this.formInline[item.keyName]=''
        }
      })
    },
    
    methods:{
      onSubmit(){},
      search(item0){
        //  学科 班型的联动
        if(item0&&item0.keyName=='ClassCourse'&&item0.action=='changeThenFiltClassModel'){
          this.searchItems.forEach(item=>{
            if(item.keyName=='ClassModel'){
              // 如果没有 就备份一个，备份过了就不能再操作，第一次进来时候才是完整的值
              if(!this.classModelSelectors.length){
                this.classModelSelectors = [].concat(item.selectors);
              }
              item.selectors = this.classModelSelectors.filter(item2=>{
                // console.log(this.formInline[item0.keyName],item2.CourseID)
                return !this.formInline[item0.keyName]||item2.CourseID==this.formInline[item0.keyName];
              })
              // console.log(item.selectors,'sel')
            }
          });

          if(this.formInline['ClassCourse']){
            this.formInline['ClassModel']&&(this.formInline['ClassModel'] = null);
          }
          // console.log(this.searchItems,'sel')
        }
        
        /** 查询参数消除空格 */
        if(this.formInline.StuKeyWord){
          this.formInline.StuKeyWord = this.formInline.StuKeyWord.replaceAll(' ', '');
        }else if(this.formInline.ClassKeyWord){
          this.formInline.ClassKeyWord = this.formInline.ClassKeyWord.replaceAll(' ', '');
        }else if(this.formInline.ExamKeyWord){
          this.formInline.ExamKeyWord = this.formInline.ExamKeyWord.replaceAll(' ', '');
        }
        const searchModel = this.$dpcp(this.formInline);
        this.$emit('search',searchModel);
      },
    }
  }
</script>

<style lang="less" scoped>
.search_btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  // border-left:none;
  // border-right: none;

}
.el-form-item:last-of-type{
  margin-right:25px;
}
// .searchbar{
//   .el-button+.el-button{
//     margin-left:0;
//   }
//   .search_btn{
//     margin-right: 10px;
//   }
// }
</style>
<style lang="less">
.searchbar{
  .el-input-group__append{
    border:0;
    overflow: hidden;
  }
}
</style>

