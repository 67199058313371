// 直播室
const liveRoutes = [
  {
    path: '/liveStudio',
    component: () => import('../views/live-studio/class-big'),
  },
  {
    path: '/liveStudioSmall',
    component: () => import('../views/live-studio/class-small'),
  },
  {
    path: '/liveStudioDouble',
    component: () => import('../views/live-studio/class-small-double-teacher'),
  },
]

// 我的课程
const childrenTeacherRoutes = [
  {
    path: '/myClass',
    component: () => import('../views/my-class'),
    meta:{keepAlive:true}
  },
  {
    path: '/myClassDetail',
    component: () => import('../views/my-class/ClassDetail.vue'),
  },
]
export default {
  liveRoutes,
  childrenTeacherRoutes
}