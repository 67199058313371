<template>
  <p class="title_wrap ft18 font-text">
    <i></i>
    <span class="span1">{{title}}</span>
  </p>
</template>

<script>
export default {
  props:{
    title:String
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/self-theme-color.less';
.title_wrap{
  margin: 24px 0 22px;
  line-height: 18px;
}
i{
  height: 18px;
  width: 2px;
  display: inline-block;
  vertical-align: bottom;
  background:@blue;
  margin-right:10px;
}
.span1{
  display: inline-block;
  vertical-align: bottom;
}
</style>
