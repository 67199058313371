<template>
    <div v-loading="loading">
        <el-dialog :title="title" :visible.sync="dialogVisible" @close="close">
            <el-table  max-height="600" :data="list">
                <el-table-column v-for="(item, i) in columns" :key="i" v-bind="item">
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
        },
        title: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            list: [],
        }
    },
    methods: {
        open(data) {
            this.dialogVisible = true
            this.list =data
        },


    },

}
</script>

<style scoped>
:deep .el-dialog__body {
    padding: 0 16px 16px;
}
</style>
