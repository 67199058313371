 <template>
<!-- 这个是给上传课件->本地上传 用的，它和 视频上传弹窗 内容一样，单独拿出来用 -->
  <div>
    <p style="overflow:hidden;">
      <span style="vertical-align:middle;">上传课件</span>
      <!-- ,.doc,.docx -->
      <el-upload
        style="float:right;margin-top:-9px;margin-left:20px;"
        ref="upload"
        :on-exceed="showLimitTip"
        class="upload-demo-text"
        action="suibian"
        accept=".pdf"
        :on-change="fileChangeOrRemove"
        :on-remove="fileChangeOrRemove"
        :http-request="batchUpload"
        :show-file-list="false"
      >
        <el-button type="text">继续添加</el-button>
      </el-upload>
      <!-- :auto-upload="false" -->
    </p>


    <div class="box radius6">
      <!-- v-if="!Object.keys(fileObj).length" -->
      <el-upload
        v-if="!exist"
        style="display:inline-block;margin:0 10px;"
        ref="upload"
        :on-exceed="showLimitTip"
        class="upload-demo"
        action="suibian"
        accept=".pdf"
        :on-change="fileChangeOrRemove"
        :on-remove="fileChangeOrRemove"
        :http-request="batchUpload"
      >
        <!--el-icon-upload iconfont icon-shangchuan1 style="font-size:35px;color:#3870F6;"-->
        <i class="iconfont icon-shangchuan1 upload_icon_bg_cicle" ></i>
        <br/>
        <el-button
          style="padding:7px 15px;"
          size="small"
          type="text"
          class="color9 ft16"
          >点击添加</el-button>
      </el-upload>

      <p
        v-for="(item,i) in formData.fileList"
        :key="i"
        class="file_text"
      >
        <span class="one_line_wrap">
          <span class="iconfont icon-pdf align_mid" style="color:#a3c3f6;margin-right:3px;"></span>
          <span class="text_one_elipsis one_elip align_mid">{{item.name||'--'}}</span>
        </span>
        <span>
          <el-progress
            v-if="Number(item.progressPercent)!=NaN&&Number(item.progressPercent) < 100"
            type="circle"
            width="14"
            :show-text="false"
            :percentage="item.progressPercent"
            color="#3870F6"
            stroke-width="2"
            style="vertical-align:middle;margin-right:10px;"
          ></el-progress>
          <span v-else-if="item.progressPercent==501" class="iconfont icon-yichang"></span>
          <span v-else-if="item.uploading" class="el-icon-loading" style="margin-right:10px;"></span>
          <span v-else class="check_icon_css el-icon-check"></span>
          <span class="el-icon-delete del_icon_css pointer align_mid" @click="removeFile(item,i)" ></span>
        </span>
      </p>
    </div>

    <div class="tipsHtml" v-html="tipsHtml" ></div>

    <p class="tc" style="margin-top:30px;">
      <el-button
        type="primary"
        @click.native="upload"
        :disabled="loading"
        :loading="loading"
      >确定</el-button>
    </p>

  </div>
</template>

<script>
let axios = window.axios;
let AliyunUpload = window.AliyunUpload;
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      loading: false,
      obj:{},
      uploadConfigObj:{

      },
      file:null,
      statusText:'',
      progressPercent:'',
      exist:false,

      uploadDisabled: true,
      resumeDisabled: true,
      pauseDisabled: true,
      uploader: null,

      formData: {
        fileList: [],
      },

      fileList: [],
      fileObj:{a:1,bb:1},

      tipsHtml:`
        <p class="color3">提示:</p>
        <p class="ft14 color9">
          1. 文件上传大小 < 10M；<br />
          2. PDF文件请使用Office转换；<br />
          3. 请提前12小时上传课件，避免影响上课使用。
        </p>`,

    };
  },
  computed:{
    ...mapGetters(['getUserInfo','getOneLiveLesson'])
  },

  methods: {
    dataIn(obj,usedAs){
      this.dataReset();
      this.obj = obj;
      this.usedAs = usedAs;
    },
    upload() {
      // console.log(this.obj,'obj--')
      if(!this.formData.fileList.length){
        this.$tipDefault('您还没有上传文件')
        return false;
      }
      // console.log(this.formData.fileList,'list',this.usedAs)
      let LectureIDs = [];
      this.formData.fileList.forEach(item=>{
        LectureIDs.push(item.LectureID)
      })
      if(this.$route.path.indexOf('liveStudio')>-1){
        //
        const data={
          ClassID:this.getOneLiveLesson.ClassID,
          LessonID:this.obj.ID,
          LectureID:LectureIDs.join(','),
        };
        this.loading = true;
        this.$api.liveAddLectureDoc(data).then(res=>{
          this.$tipOk('已上传完成')
          this.loading = false;
          this.$emit('handleDone',{
            type:'uploadCourseware',
          })
          this.dataReset();
        }).catch(err=>{
          this.loading = false;
        })

      }else{
        // 如果是讲次上传课件  上传之后还要绑定到对应讲次上面
        if(this.usedAs=='ClassTimesManage'){
          // console.log(this.formData.fileList,'list')
          const data={
            ClassID:this.$route.query.classId,
            LessonID:this.obj.ID,
            LectureID:LectureIDs.join(','),
          };
          this.$api.AddLectureDoc(data).then(res=>{
            this.$tipOk('已上传完成')
            this.$emit('handleDone',{
              type:'uploadCourseware',
            })
            this.dataReset();
          }).catch(err=>{

          })
        }
        // 如果是课件管理的上传文件，要上传到对应的文件目录位置
        // else if(this.usedAs=='CoursewareManage'&&this.obj.type=='uploadFileToCurrentFolder'){
        //   console.log(this.formData.fileList,'list')
        //   const data={
        //     LectureID:LectureIDs.join(','),
        //     PLectureID:this.obj.PLectureID
        //   };
        //   this.$api.MoveLecture(data).then(res=>{
        //     this.$tipOk('已上传完成')
        //     this.$emit('handleDone',{
        //       type:'uploadCourseware',
        //     })
        //     this.dataReset();
        //   }).catch(err=>{

        //   })
        // }
        else{
          // 否则就直接上传一下就好了，直接到课件管理了
          this.$emit('handleDone',{
            type:'uploadCourseware',
          })
          this.dataReset();
        }
      }

    },
    dataReset(){
      this.formData={
        fileList: [],
      };
      this.obj={};
      this.exist = false;
    },
    fileChangeOrRemove(file,fileList){
      console.log('5566',file)
      if(!fileList.length){
        this.exist=false;
      }else{
        this.exist=true;
      }

    },
    removeFile(item,i){
      const data = {
        LectureID:item.LectureID
      }
      this.$api.DelLectureDoc(data).then(res=>{
        this.$tipDefault('已删除')
        this.formData.fileList.splice(i,1);
        this.exist = !!this.formData.fileList.length;
      }).catch(err=>{
        this.$tipDefault(err.ErrMsg);
      })
    },
    getUploadObj(title,fileName){
      // const data = {
      //   VideoTitle:title,
      //   VideoFileName:fileName
      // }
      // this.$api.GetUploadTicket(data).then(res=>{
      //   let {UploadAddress,UploadAuth,RequestId,VideoId} = res.TicketInfo;
      //   let userId = res.AliUserID;
      //   let UploadAddress2 = JSON.parse(window.atob(res.TicketInfo.UploadAddress));

      //   let UploadAuth2 =  JSON.parse(window.atob(res.TicketInfo.UploadAuth));
      //   // console.log(UploadAddress2,'\n',UploadAuth2)

      //   this.uploadConfigObj = {
      //     RequestId,
      //     VideoId,
      //     UploadAddress:UploadAddress2,
      //     UploadAuth:UploadAuth2,
      //     userId,
      //   }
      //   // console.log(this.uploadConfigObj)

      // }).catch(err=>{})
    },

    authUpload () {
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload()
        this.uploadDisabled = true
        this.pauseDisabled = false
      }
    },


    //自定义上传
    batchUpload(fileObj) {
      console.log(fileObj.file, '自定义');
      let file = fileObj.file;
      const {name,size} = file;
      if (size / 1024 / 1024 > 10) {
        this.$message({
          customClass:'article_msg',
          type:'warning',
          message:'文件大小不超过10M',
          duration:3000,
        });
        return;
      }
      // ||name.includes('.docx')||name.includes('.doc')
      if (name.includes('.pdf')) {
        this.fileObj = file;
        // 直播教师端用 uploadFileFn，管理端用 uploadPicFn
        if(location.origin.indexOf('teacher')>-1||this.$route.path.indexOf('liveStudio')>-1){
          let obj = {
            name:file.name,
            type:file.type,
            uploading:true,
          }
          this.formData.fileList.push(obj);
          let len = this.formData.fileList.length;
          this.$api.uploadFileFn('USR_RES_CLSDOC',file).then(res=>{
            this.$tipOk('已上传')
            this.formData.fileList[len-1].uploading = false;
            this.formData.fileList[len-1].LectureID = res;
            // file.LectureID = res;
          }).catch(err=>{
            console.log(err,'er.err0999')
            this.dataReset();
          })
        }else{
          // console.log(file,'f--')
          let obj = {
            name:file.name,
            type:file.type,
            uploading:true,
          }
          this.formData.fileList.push(obj)
          let len = this.formData.fileList.length;
          let PLectureID = this.obj.PLectureID;
          this.$api.uploadPicFn('USR_RES_CLSDOC',file,PLectureID).then(res=>{
            this.$tipOk('已上传')
            // file.LectureID = res;
            this.formData.fileList[len-1].uploading = false;
            this.formData.fileList[len-1].LectureID = res;
          }).catch(err=>{
            console.log(err,'er.err0999')
            this.dataReset();
          })
        }
        // this.ruleForm.DocFileName = this.fileObj;
      } else {
        this.$message({
          customClass:'article_msg',
          type: 'warning',
          message: '请上传正确的文件类型（.pdf)',
          duration:3000,
        });
      }
    },
    //下载模板
    downloadTmp(){
      let config={
        params:{
          ...this.searchModel
        }
      }
      this.loading0=true;
      this.$api.getScoreTmp(config).then(res=>{
        this.loading0=false;
        //window.location.href=res.data;
        let {originalName,url}=res.data;
        this.$utils.download(url,originalName);
      }).catch(()=>{
        this.loading0=false;
      })
    },

    showLimitTip(){
      this.$message('只能上传一个文件')
    },



    handleNull(rule, val, cb) {
      if (!val && val !== null) {
        cb(new Error(rule.messge));
      } else {
        cb();
      }
    },


    //上传之前的文件格式校验
    // beforeUpload(file) {
    //   let accept = '.jpg,.jpeg';
    //   // return this.$comBeforeUpload(file, accept, [15, 180]);
    // },
    //上传图片的被调
    beCalledFn(res, file, fileList) {
      this.formData.fileList = [].concat(fileList);
    },
    //删除照片回调
    handleRemove(file, fileList) {
      this.formData.fileList = [].concat(fileList);
    },
    //超出文件个数的回调
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 1 个文件，已选择了 1 个文件`);
    // },
  },
  mounted() {
    console.log('n---n',this.usedAs,this.row)
  },
};
</script>

<style lang="less" scoped>
.box{
  height:170px;
  border: 1px dashed #d9d9d9;
  overflow: auto;
  background-color:#F9FBFC;

}

.upload_res_img{
  display:inline-block;
  width:45px;
  height:45px;
  margin-bottom:12px;
  border-radius: 50%;
  background-color:green;

  // background:url('~@/assets/no_content.png') left top no-repeat;
  // background-size:100%;
}
.file_text{
  width: 100%;
  height:30px;
  padding:0 10px;
  transition: all .5s cubic-bezier(.55,0,.1,1);
  transition: color .3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .one_line_wrap{
    width:80%;
    .text_one_elipsis{
      display: inline-block;
      width:calc(100% - 20px);
    }
  }
  .del_icon_css{
    vertical-align: middle;
    // float:right;
    // margin-top:13px ;/
    // color:red;
    // cursor: pointer;
  }
}
.check_icon_css{
  display:inline-block;
  font-size: 11px;;
  padding:1px;
  margin-right:10px;
  color:#ffffff;
  background:green;
  border-radius:50%;
}
.upload-demo{
  margin:0!important;
  width: 100%;
  height: 168px;

  display: flex!important;
  justify-content: center;
  align-items:center;
  .el-upload{
    display: block;
  }
}
.tipsHtml{
  margin-top:10px;
  line-height: 24px;
  p{
    line-height: 32px;
  }
}
</style>

