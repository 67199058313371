import axios from "axios";
import vue from 'vue';
import store from "../store";
// import store from 'store';


function onError(message) {
  vue.prototype.$message(message)
}
// const baseUrl = "https://jumpserver.95059.com.cn:1178/";
const instance = axios.create({
  // baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
  withCredentials: true,
});
// 拦截器，可以对request response 做预处理工作
instance.interceptors.request.use(
  config => config,
  err => Promise.reject(err)
);

instance.interceptors.response.use(
  response => {

    //console.log('ResponseInfo:', response.config.url)
    if(['/DownloadPic','/DownloadRes','/DownloadRES', '/DownloadExamPaper'].includes(response.config.url)){
      // if(response.config.url=='/Code'){
        // console.log(response,1111)

        if(response.data.ErrCode){
          onError(response.data.ErrMsg)
          if(response.data.ErrCode==1){
            vue.prototype.$utils.delLocalUserData();
            setTimeout(()=>{
              // window.location.href = location.href.split('#')[0]+'#/login'
              window.location.href = vue.prototype.$reloadLoginUrl;
            },800)
          }
        }else{
          return response.data;
        }

        // Promise.resolve(response.data);
    }else if (response.status === 200) {
      if (response.data.ErrCode==0) {
        return response.data;
      }else{
        // onError(response.data.ErrMsg);
        // /**
        //  * 96 教师信息不存在
        //  *
        //  */

        // ErrCode 1 用户未登录;
        if(response.data.ErrCode==1){
          onError(response.data.ErrMsg);
          // 删除缓存用户数据
          store.commit('delUserInfo');

          setTimeout(()=>{
            // window.location.href = location.href.split('#')[0]+'#/login'
            window.location.href = vue.prototype.$reloadLoginUrl;
          },800)

          return Promise.reject(response.data);
        }else{
          onError(response.data.ErrMsg);
          return Promise.reject(response.data);
        }
      }
    }else{
      onError(response.data.ErrMsg);
      return Promise.reject(response.data);
    }

  },
  error => {
    const message =
      (error.response && error.response.data.msg) || error.message;
    onError(message);
    return Promise.reject(error);
  }
);

export default instance;
