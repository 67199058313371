// import request from '@/api/request'
// import store from '@/store'
// import CryptoMD5 from 'crypto-js/md5';
import md5 from 'blueimp-md5';

// npm install blueimp-md5
// <script src="js/md5.min.js"></script>
// md5()

export default {
  install: (Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$utils = {
      cryptoPass(word) {
        // 加密密码
        // return CryptoMD5(word).toString();
        return md5(word);
      },
      addEvent(obj,ev,fn) //obj为要绑定事件的元素，ev为要绑定的事件，fn为绑定事件的函数
      {
        if(obj.addEventListener){
            obj.addEventListener(ev,fn,false);
        }else if(obj.attachEvent){
              obj[ev+fn] = function(){
                fn.call(obj);
                }
            obj.attachEvent("on" + ev,obj[ev+fn]);
        }else {
            obj['on'+ev]=fn;
        }
      },
      removeEvent(obj,ev,fn){
        if(obj.removeEventListener){
          obj.removeEventListener(ev,fn,false);
          // console.log('yichu')
        }else if(obj.detachEvent){
          obj.detachEvent("on" + ev, obj[ev+fn]);
        }else {
          obj['on'+ev]=null;
        }
      },
      isIE() { //ie?
        if (!!window.ActiveXObject || "ActiveXObject" in window)
         return true;
         else
         return false;
      },
      IEVersion() {
        var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
        var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
        var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
        if(isIE) {
            var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
            reIE.test(userAgent);
            var fIEVersion = parseFloat(RegExp["$1"]);
            if(fIEVersion == 7) {
                return 7;
            } else if(fIEVersion == 8) {
                return 8;
            } else if(fIEVersion == 9) {
                return 9;
            } else if(fIEVersion == 10) {
                return 10;
            } else {
                return 6;//IE版本<=7
            }
        } else if(isEdge) {
            return 'edge';//edge
        } else if(isIE11) {
            return 11; //IE11
        }else{
            return -1;//不是ie浏览器
        }
      },
      /**
       * 项目用的枚举值的 转换
       * @param {枚举值 的key} key
       * @param {当前的这个 枚举值} val
       */
      filtName(key,val,baseData){
        let arr = baseData[key+'Info'].filter(
          (item) => val == item[key+'ID'],
        )
        return arr.length>0?arr[0][key+'Name']:val;
      },
      phoneToStar(phone,index,char,num){
        let numCode = '';
        for(let i=0;i<num;i++){
          numCode += char;
        }
        return phone.substring(0, index) + numCode + phone.substring(index + num);
      },

      downloadBlob(res,name,fn){
        let blob = new Blob([res],{type: res.type});

        let link = document.createElement('a')
        // {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
        link.style.display = 'none'
        link.target = '_blank'
        link.href = URL.createObjectURL(blob);
        console.log('link',link.href)

        if(fn){
          fn(link.href)
        }else{
          let num = ''
          for(let i=0;i < 10;i++){
            num += Math.ceil(Math.random() * 10)
          }
          if(name){
            console.log(name)
            link.setAttribute('download', name)
          }

          // link.setAttribute('download', '兑现列表.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      },

      isLeftMenu(path){
        let arr = [
          // 左侧菜单项
          '/dashboard',
          '/classManage',
          '/liveVideoManage',
          '/studentManage',
          '/staffManage',
          '/coInfo',
          '/myClass',
          '/quizBaseManage',
          '/examMgr',
          
          // 账号设置相关
          '/editAccount',
          '/accountCertify',
          '/editPass',

        ]
        return arr.includes(path)
      },

      delLocalUserData:()=>{
        localStorage.removeItem('userInfo');
        localStorage.removeItem('baseMapData');
        localStorage.removeItem('teacherList');
      },

      getAccessArr:(accessStr)=>{
        // 学生 班级的 放到教务分组
        // 老师的 机构的 系统的 放到基础设置
        let baseObj = {
          'PUR_AGT_EDIT':0,
          'PUR_STU_ADD': 1,
          'PUR_STU_DEL': 2,
          'PUR_STU_EDIT':3,
          'PUR_STU_LIST':4,
          'PUR_TCH_ADD': 5,
          'PUR_TCH_DEL': 6,
          'PUR_TCH_EDIT':7,
          'PUR_TCH_LIST':8,
          'PUR_CLS_ADD': 9,
          'PUR_CLS_DEL': 10,
          'PUR_CLS_EDIT':11,
          'PUR_CLS_LIST':12,
          'PUR_CLS_IN':  13,
          'PUR_CLS_OUT': 14,
          'PUR_VOD_CHK': 15,
          'PUR_SYS_CFG': 16,
          'PUR_STU_VIEW': 17,
          'PUR_TCH_VIEW': 18,
          'PUR_CLS_WATCH': 19,
          'PUR_EXAM_ADD': 20,
          'PUR_EXAM_DEL': 21,
          'PUR_EXAM_CHK': 22,
          'PUR_EXAM_EDIT': 23,
          'PUR_QST_ADD': 24,
          'PUR_QST_DEL': 25,          
        }
        let baseObjStr = {
          'PUR_AGT_EDIT':'编辑单位',
          'PUR_STU_ADD': '新增学员',
          'PUR_STU_DEL': '删除学员',
          'PUR_STU_EDIT':'编辑学员',
          'PUR_STU_LIST':'学员列表',
          'PUR_TCH_ADD': '新增员工',
          'PUR_TCH_DEL': '删除员工',
          'PUR_TCH_EDIT':'编辑员工',
          'PUR_TCH_LIST':'员工列表',
          'PUR_CLS_ADD': '新建班级',
          'PUR_CLS_DEL': '删除班级',
          'PUR_CLS_EDIT':'编辑班级',
          'PUR_CLS_LIST':'班级列表',
          'PUR_CLS_IN':'报班',
          'PUR_CLS_OUT': '退班',
          'PUR_VOD_CHK': '视频审核',
          'PUR_SYS_CFG': '系统设置',
          'PUR_STU_VIEW': '查看学员详情',
          'PUR_TCH_VIEW': '查看员工详情',
          'PUR_CLS_WATCH': '直播监课',
          'PUR_EXAM_ADD': '新增试卷',
          'PUR_EXAM_DEL': '删除试卷',
          'PUR_EXAM_CHK': '批改试卷',
          'PUR_EXAM_EDIT': '编辑试卷',
          'PUR_QST_ADD': '新增试题',
          'PUR_QST_DEL': '删除试题',          
        }
        let accessObj = {
          teachGroup:[],
          setGroup:[],
          examGroup:[],
        };
        let baseArr = Object.keys(baseObj);
        let accessNumber = accessStr;
        const purviewLen = baseObj.length;

        /* 数据库存储的权限长度于实际长度不足时往后依次补0 ，多于实际长度时从前开始截取实际长度 */
        if(accessNumber.length < purviewLen){
          let num = purviewLen - accessNumber.length;
          let pushStr = '';
          for(let i=0;i<num;i++){
            pushStr += '0';
          }
          accessNumber += pushStr;
          console.log('补充权限', accessNumber);
        }else{
          accessNumber = accessNumber.substring(0,purviewLen);
          console.log('截取权限', accessNumber);
        }
        let baseRealAccessArr = accessNumber.split('');

        baseRealAccessArr.forEach((item,i)=>{
          // 有权限的 单独拿出来  有权限的：1  没有权限：0
          // 注释：这个权限暂时给 员工角色展示用，不做操作权限限制，将来需要再说
          if(baseArr[i]){
            if(baseArr[i].indexOf('Stu')>-1||baseArr[i].indexOf('CLS')>-1){
              accessObj.teachGroup.push({
                label:baseObjStr[baseArr[i]],
                key:baseArr[i],
                val:item,
              })
            }else if(baseArr[i].indexOf('EXAM')>-1||baseArr[i].indexOf('QST')>-1){
              accessObj.examGroup.push({
                label:baseObjStr[baseArr[i]],
                key:baseArr[i],
                val:item,
              })
            }else{
              accessObj.setGroup.push({
                label:baseObjStr[baseArr[i]],
                key:baseArr[i],
                val:item,
              })
            }
          }

        })
        return accessObj;
      },
      /**
       * name 文件名 带后缀
       * size 文件字节数
       * maxSize 文件大小 最大值
       * nameType 文件要求的 格式
       */
      checkFileSizeType(){},
      calcFileSize(num){
        if(num!=null){
          let val = Number(num);
          return val/1024/1024/1024>1?(val/1024/1024/1024).toFixed(2)+'GB':(val/1024/1024>1?(val/1024/1024).toFixed(2)+'M':(val/1024).toFixed(2)+'K')||'-'
        }else{
          return '-'
        }
      },

      /** 将服务器返回的子题目合并到题干信息上 */
      bindSubQuiz2Master(masterQuizSet, subQuizSet){
        if(!masterQuizSet || !subQuizSet){
            return;
        }
        for(let idx=0; idx < subQuizSet.length; idx++){
          for(let quizIdx=0; quizIdx < masterQuizSet.length; quizIdx++){
            let masterQuizID = masterQuizSet[quizIdx].questionId?masterQuizSet[quizIdx].questionId:masterQuizSet[quizIdx].id;
            if(masterQuizID == subQuizSet[idx].pid){
              if(masterQuizSet[quizIdx].SubQuiz){
                masterQuizSet[quizIdx].SubQuiz.push(subQuizSet[idx])
              }else{
                masterQuizSet[quizIdx].SubQuiz = [subQuizSet[idx]];
              }
              break;
            }
          }
        }
      },
    }
  },
};
