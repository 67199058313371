import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/layout';

import teacherRoutes from './routerTeacher'
import manageRoutes from './routerManage'

import Login from '../views/login/Login.vue';
Vue.use(VueRouter);

const {liveRoutes,childrenTeacherRoutes} = teacherRoutes;

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/login',
    //redirect: window.sysType==2?'/myClass':'/dashboard',
    // children:[
    //   // 管理端路由 or
    //   // 我的课程
    // ]
  },

  {
    path: '/loginPage',
    redirect:'/login',
    component:Login,
    children:[
      {
        path: '/login',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/login/components/LoginBlock1.vue'),
      },
      {
        path: '/findPass',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/login/components/FindPass.vue'),
      },
      {
        path: '/findPassSuccess',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/login/components/FindPassOk.vue'),
      },
    ]
  },

  // 直播室路由放置此处

];

if(window.sysType==2){
  routes[0].children=childrenTeacherRoutes;
  routes.push(...liveRoutes)
}else{
  routes[0].children=manageRoutes;
}

const router = new VueRouter({
  routes,
});

export default router;
