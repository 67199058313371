<template>
<span :class="iconClass" :style="{fontSize:ft||''}"></span>
</template>
<script>
export default {
  props:{
    fileType:String,
    ft:String
  },
  data() {
    return {
      iconClass:'',
    }
  },
  mounted(){
    let preStr = 'iconfont icon-';
    let afterStr = '';
    if(this.fileType == 'wenjianjia10'){
      afterStr = 'wenjianjia10 colory';
    }else if(this.fileType == 'pdf'){
      afterStr = 'pdf red';
    }else{
      afterStr = this.fileType+' theme_color';
    }
    this.iconClass = preStr + afterStr;

  }
}
</script>
<style lang=''>
</style>
