const manageRoutes = [
  {
    path: '/dashboard',
    component: () => import('../views/dashboard'),
  },
  {
    path: '/editAccount',
    component: () => import('../views/setting-page/EditAccountInfo.vue'),
  },
  {
    path: '/accountCertify',
    component: () => import('../views/setting-page/AccountCertify.vue'),
  },
  {
    path: '/editPass',
    component: () => import('../views/setting-page/EditPass.vue'),
  },

  {
    path: '/classManage',
    component: () => import('../views/manage-class'),
    meta:{keepAlive:true}
  },

  /**
   *  直播管理 页面和 班级班级几乎相似，所以此处用一个页面，页面内
   * 根据 路由 识别请求 直播列表 或者 班级(录播)列表
   */
  {
    path: '/liveVideoManage',
    component: () => import('../views/manage-class'),
    meta:{keepAlive:true}
  },
  // 和讲次管理同一个页面，个别按钮不同，新增弹窗不同
  {
    path: '/liveClassTimesManage',
    component: () => import('../views/manage-class/ClassTimesManage.vue'),
    meta:{keepAlive:true}
  },
  // 和班级详情同一个页面
  {
    path: '/liveClassDetail',
    component: () => import('../views/manage-class/ClassDetail.vue'),
  },
  // 学习记录
  {
    path: '/liveLearnRecord',
    component: () => import('../views/manage-class/LearnRecord.vue'),
  },
  // 课件管理
  {
    path: '/coursewareManage',
    component: () => import('../views/manage-class/CoursewareManage.vue'),
  },

  {
    path: '/suspend',
    component: () => import('../views/testFile/SuspendClass.vue'),
  },


  {
    path: '/studentManage',
    component: () => import('../views/manage-student'),
    meta:{keepAlive:true}
  },
  {
    path: '/staffManage',
    component: () => import('../views/manage-staff'),
    meta:{keepAlive:true}
  },
  {
    path: '/coInfo',
    component: () => import('../views/company-info'),
  },
  {
    // 系统设置
    path: '/sysCfg',
    component: () => import('../views/system-cfg'),
  },
  {
    path: '/sysLog',
    component: () => import('../views/system-log'),
  },

  {
    path: '/classDetail',
    component: () => import('../views/manage-class/ClassDetail.vue'),
  },
  {
    path: '/classTimesManage',
    component: () => import('../views/manage-class/ClassTimesManage.vue'),
    meta:{keepAlive:true}
  },
  /** 实时监课页面/助理页面 */
  {
    path: '/liveAssistant',
    component: () => import('../views/live-studio/class-assistant'),
    meta:{keepAlive:false}
  },

  {
    path: '/videoManage',
    component: () => import('../views/manage-class/VideoManage.vue'),
  },

  {
    path: '/stuDetail',
    component: () => import('../views/manage-student/StuDetail.vue'),
  },
  {
    path: '/informationAudit',
    component: () => import('../views/manage-student/authentication.vue'),
  },

  {
    path: '/staffDetail',
    component: () => import('../views/manage-staff/StaffDetail.vue'),
  },

  {
    path: '/addStaff',
    component: () => import('../views/manage-staff/AddEditStaff.vue'),
  },
  {
    path: '/editStaff',
    component: () => import('../views/manage-staff/AddEditStaff.vue'),
  },

  {
    path: '/quizBaseManage',
    component: () => import('../views/manage-quizbase'),
  },
  {
    path: '/examMgr',
    component: () => import('../views/manage-exampaper'),
  },
  {
    path: '/previewExamPaper',
    component: () => import('../views/manage-exampaper/ExamPaper.vue'),
  },
  {
    path: '/listExamRecords',
    component: () => import('../views/manage-exampaper/ExamRecords.vue'),
  },
  {
    path: '/previewAnswerPaper',
    component: () => import('../views/manage-exampaper/AnswerPaper.vue'),
  },
]
export default manageRoutes;