<template>
  <div class="login_container wh100" style="position:relative;">

    <div class="logo">
      <img src="@/assets/zhuo_logo.png" width="100%" alt="卓学云" />
    </div>
    <div ref="centerWrap" class="login_wrap center_wrap bg_white">
      <router-view></router-view>
    </div>

    <div class="footer">
      <FooterCopyright :notLogin="false" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less">
.login_container {
  // background: url('../../assets/login_bg.png') no-repeat left top;
  // background-size: 100% 100%;
  background: #f3f8fe;
  min-width: 1024px;
  overflow: hidden;
  position: relative;
  .logo {
    position: absolute;
    left: 90px;
    top: 50px;
    width: 239px;
  }
  .login_wrap {
    width: 1000px;
    height: 560px;
    border-radius: 30px;
    // margin: 0 auto;
    // background: url(../../assets/logo_wrap_bg.png) no-repeat left top;
    // background-size: 100%;
  }
  .center_wrap {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .footer {
    position: absolute;
    bottom: 33px;
    left: calc(50% - 322px);
  }
}
</style>
