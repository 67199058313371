<template>
    <div v-loading="loading">
        <el-dialog title="测验报告" :visible.sync="dialogVisible" @close="close">
            <h4>本班学员得分：</h4>
            <el-table max-height="250" :data="StuExamOverInfo"  border style="width: 100%">
                <el-table-column width="70" prop="Name" label="学员姓名"></el-table-column>
                <el-table-column width="50" v-for="(item, index) in  ExamPaperOverInfo"  :key="index" :label="index+1" >
                    <template slot-scope="scope">
                        <div >{{scope.row.scoreList? scope.row.scoreList[index]:'-' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="examTotalScore" label="总分" width="100"></el-table-column>
                <el-table-column prop="Ranking" label="排名" width="100"></el-table-column>
            </el-table>
            <h4>测验各题情况：</h4>
            <el-table max-height="250" :data="ExamPaperOverInfo" style="margin-top: 20px">
                <el-table-column property="questionSort" label="题目编号"></el-table-column>
                <el-table-column property="CorrectRatio" label=" 正确率(%)"></el-table-column>
                <el-table-column property="questionSort" label="难易度"></el-table-column>
                <el-table-column property="knowledges" label="知识点"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="测验报告" :visible.sync="showSelectExamPaper" @close="close">
            <el-radio-group v-model="ExamPaperID">
                <el-radio :label="item.ExamPaperID" v-for="item in ExamPaperList" :key="item.ExamPaperID">{{
                    item.mainTitleName
                    }}</el-radio>

            </el-radio-group>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showSelectExamPaper = false">取消</el-button>
                <el-button type="primary" @click="onOk">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

        apiName: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            dialogVisible: false,
            showSelectExamPaper: false,
            loading: false,
            ExamPaperOverInfo: [],

            ExamPaperID: '',
            ExamPaperList: [],
            params: {}
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {


        onOk() {
            this.dialogVisible = false
            this.getList()
        },
        open(row) {
            this.params = { ClassID: row.ClassID, LessonID: row.ID, }
            this.loading = true;
            this.$api['ListLessonExamPaper']({ "ClassID": row.ClassID, "LessonID": row.ID }).then(res => {
                if (res.ExamPaperInfo.length > 1) {
                    this.ExamPaperID = res.ExamPaperInfo[0].ExamPaperID
                    this.showSelectExamPaper = true
                    this.ExamPaperList = res.ExamPaperInfo
                } else {
                    this.ExamPaperID = res.ExamPaperInfo[0].ExamPaperID
                    this.dialogVisible = true
                    this.getList()
                }

                this.loading = false;
            }).catch(err => {
                console.log(err)
                this.loading = false;
            });
        },
        getList() {

            this.loading = true;
            this.$api[this.apiName]({ ...this.params, ExamPaperID: this.ExamPaperID }).then(res => {
                this.loading = false;
                this.ExamPaperOverInfo = res.ExamPaperOverInfo;
                this.StuExamDetailInfo = res.StuExamDetailInfo;
                res.StuExamOverInfo.forEach(element => {
                    res.StuExamDetailInfo.forEach(item => {
                        if (element.StuID === item.StuID) {
                            // 创建一个空数组用于存储数值
                            let arr = [];
                            // 获取对象的所有键
                            let keys = Object.keys(item);

                            // 遍历所有键，检查是否为数字，如果是则添加到数组中
                            keys.forEach(key => {
                                if (!isNaN(key) && arr.length < res.ExamPaperOverInfo.length) { // 检查键是否为数字
                                    arr.push(item[key]);
                                }
                            });
                            element.scoreList = arr

                        }
                    })
                });
                this.StuExamOverInfo = res.StuExamOverInfo;
                console.log(this.StuExamOverInfo)
            }).catch(err => {
                console.log(err)
                this.loading = false;
            });
        },
    }

}
</script>

<style scoped>
:deep .el-dialog__body {
    padding:  0 16px 16px;

}
</style>
