import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const handleGetter=(state0,key)=>{
  return Object.keys(state0[key]).length>0 ? state0[key]:(localStorage.getItem(key)?JSON.parse(localStorage.getItem(key)) : {})
  //Object.keys(state.teachers).length>0 ? state.teachers:(localStorage.getItem('teachers')?JSON.parse(localStorage.getItem('teachers')) : {})
}
const handleArrGetter=(state0,key)=>{
  return state0[key].length>0 ? state0[key]:(localStorage.getItem(key)?JSON.parse(localStorage.getItem(key)) : [])
}

export default new Vuex.Store({
  state: {
    userInfo:{},
    oneStu:{},
    oneStaff:{},
    oneClass:{},
    oneClassTime:{},
    classObj:{},
    // crumbNavList:[],
    oneLiveLesson:{},
    keepAliveArr:[],
    oneVideoInfo:{},
    oneCourseInfo:{},
  },

  getters:{
    getAccountInfo(state){
      return handleGetter(state,'accountInfo')
    },
    getOneCourseInfo(state){
      var info = Object.keys(state.oneCourseInfo).length>0?state.oneCourseInfo:localStorage.getItem('oneCourseInfo')?JSON.parse(localStorage.getItem('oneCourseInfo')):{};
      return info;
    },
    getOneVideoInfo(state){
      var info = Object.keys(state.oneVideoInfo).length>0?state.oneVideoInfo:localStorage.getItem('oneVideoInfo')?JSON.parse(localStorage.getItem('oneVideoInfo')):{};
      return info;
    },
    getAppConfig(state){
      return handleGetter(state,'appConfig');
    },

    getUserInfo:(state)=>{
      return handleGetter(state,'userInfo')
    },
    getBaseMapData:()=>JSON.parse(localStorage.getItem('baseMapData')),
    getTeachers:()=>JSON.parse(localStorage.getItem('teacherList')),
    getOneClass:(state)=>{
      return handleGetter(state,'oneClass')
    },
    getOneClassTime:(state)=>{
      return handleGetter(state,'oneClassTime')
    },
    getOneStu:(state)=>{
      return handleGetter(state,'oneStu')
    },
    getOneStaff:(state)=>{
      return handleGetter(state,'oneStaff')
    },

    getAccessArr:(state)=>{
      // 学生 班级的 放到教务分组
      // 老师的 机构的 系统的 放到基础设置
      let baseObj = {
        'PUR_AGT_EDIT':0,
        'PUR_STU_ADD': 1,
        'PUR_STU_DEL': 2,
        'PUR_STU_EDIT':3,
        'PUR_STU_LIST':4,
        'PUR_TCH_ADD': 5,
        'PUR_TCH_DEL': 6,
        'PUR_TCH_EDIT':7,
        'PUR_TCH_LIST':8,
        'PUR_CLS_ADD': 9,
        'PUR_CLS_DEL': 10,
        'PUR_CLS_EDIT':11,
        'PUR_CLS_LIST':12,
        'PUR_CLS_IN':  13,
        'PUR_CLS_OUT': 14,
        'PUR_VOD_CHK': 15,
        'PUR_SYS_CFG': 16,
        'PUR_STU_VIEW': 17,
        'PUR_TCH_VIEW': 18,
        'PUR_CLS_WATCH': 19,
        'PUR_EXAM_ADD': 20,
        'PUR_EXAM_DEL': 21,
        'PUR_EXAM_CHK': 22,
        'PUR_EXAM_EDIT': 23,
        'PUR_QST_ADD': 24,
        'PUR_QST_DEL': 25,
      }
      let baseObjStr = {
        'PUR_AGT_EDIT':'编辑单位',
        'PUR_STU_ADD': '新增学员',
        'PUR_STU_DEL': '删除学员',
        'PUR_STU_EDIT':'编辑学员',
        'PUR_STU_LIST':'学员列表',
        'PUR_TCH_ADD': '新增员工',
        'PUR_TCH_DEL': '删除员工',
        'PUR_TCH_EDIT':'编辑员工',
        'PUR_TCH_LIST':'员工列表',
        'PUR_CLS_ADD': '新建班级',
        'PUR_CLS_DEL': '删除班级',
        'PUR_CLS_EDIT':'编辑班级',
        'PUR_CLS_LIST':'班级列表',
        'PUR_CLS_IN':'报班',
        'PUR_CLS_OUT': '退班',
        'PUR_VOD_CHK': '视频审核',
        'PUR_SYS_CFG': '系统设置',
        'PUR_STU_VIEW': '查看学员详情',
        'PUR_TCH_VIEW': '查看员工详情',
        'PUR_CLS_WATCH': '直播监课',
        'PUR_EXAM_ADD': '新增试卷',
        'PUR_EXAM_DEL': '删除试卷',
        'PUR_EXAM_CHK': '批改试卷',
        'PUR_EXAM_EDIT': '编辑试卷',
        'PUR_QST_ADD': '新增试题',
        'PUR_QST_DEL': '删除试题',
      }
      let accessObj = {
        teachGroup:[],
        setGroup:[],
        examGroup:[],
      };
      let baseArr = Object.keys(baseObj);
      let accessNumber = handleGetter(state,'userInfo').Purview;
      const purviewLen = baseObj.length;
      /* 数据库存储的权限长度于实际长度不足时往后依次补0 ，多于实际长度时从前开始截取实际长度 */
      if(accessNumber.length < purviewLen){
        let num = purviewLen - accessNumber.length;
        let pushStr = '';
        for(let i=0;i<num;i++){
          pushStr += '0';
        }
        accessNumber += pushStr;
      }else{
        accessNumber = accessNumber.substring(0, purviewLen);
        console.log('权限超长，截取内容：', accessNumber);
      }
      let baseRealAccessArr = accessNumber.split('');

      baseRealAccessArr.forEach((item,i)=>{
        // 有权限的 单独拿出来  有权限的：1  没有权限：0
        // 注释：这个权限暂时给 员工角色展示用，不做操作权限限制，将来需要再说
        if(baseArr[i]){
          if(baseArr[i].indexOf('Stu')>-1||baseArr[i].indexOf('CLS')>-1){
            accessObj.teachGroup.push({
              label:baseObjStr[baseArr[i]],
              key:baseArr[i],
              val:item,
            })
          }else if(baseArr[i].indexOf('EXAM')>-1||baseArr[i].indexOf('QST')>-1){
            accessObj.examGroup.push({
              label:baseObjStr[baseArr[i]],
              key:baseArr[i],
              val:item,
            })
          }else{
            accessObj.setGroup.push({
              label:baseObjStr[baseArr[i]],
              key:baseArr[i],
              val:item,
            })
          }
        }

      })
      return accessObj;
    },

    // getOneStaff:(state)=>{
    //   return handleGetter(state,'oneStaff')
    // }

    getClassObj:(state)=>{
      return handleGetter(state,'classObj')
    },
    getOneLiveLesson:(state)=>{
      return handleGetter(state,'oneLiveLesson')
    },
    getKeepAliveArr:(state)=>{
      return state.keepAliveArr;
    },
    // getCrumbNavList:(state)=>{
    //   return handleArrGetter(state,'crumbNavList')
    // },
  },

  mutations: {
    saveVideoInfo(state, payload) {
      Vue.set(state, 'oneVideoInfo', payload);
      localStorage.setItem('oneVideoInfo',JSON.stringify(payload))
    },
    removeVideoInfo(state){
      state.oneVideoInfo = {};
      localStorage.removeItem('oneVideoInfo');
    },

    saveCourseInfo(state, payload){
      Vue.set(state, 'oneCourseInfo', payload);
      localStorage.setItem('oneCourseInfo',JSON.stringify(payload))
    },
    removeCourseInfo(state){
      state.oneCourseInfo = {};
      localStorage.removeItem('oneCourseInfo');
    },

    storeUserInfo:(state,payload)=>{
      Vue.set(state, 'userInfo', payload);
      localStorage.setItem('userInfo',JSON.stringify(payload))
    },
    delUserInfo:(state)=>{
      state.userInfo = {};
      Vue.prototype.$utils.delLocalUserData();
    },
    refreshUserInfo:(state)=>{
      if(localStorage.getItem('userInfo')){
        //console.log('localStorage refreshUserInfo ok');
      }else{
        //console.log('localStorage refreshUserInfo empty');
        Vue.set(state, 'userInfo', {});
      }
    },
    storeOneClass:(state,payload)=>{
      state.oneClass = payload;
      localStorage.setItem('oneClass',JSON.stringify(payload))
    },
    storeOneClassTime:(state,payload)=>{
      state.oneClassTime = payload;
      localStorage.setItem('oneClassTime',JSON.stringify(payload))
    },
    storeOneStu:(state,payload)=>{
      state.oneStu = payload;
      localStorage.setItem('oneStu',JSON.stringify(payload))
    },
    storeOneStaff:(state,payload)=>{
      state.oneStaff = payload;
      localStorage.setItem('oneStaff',JSON.stringify(payload))
    },
    delOneStaff:(state,payload)=>{
      state.oneStaff = {};
      localStorage.removeItem('oneStaff');
    },
    delOneClass:(state,payload)=>{
      state.oneClass = {};
      localStorage.removeItem('oneClass');
    },
    delOneStu:(state,payload)=>{
      state.oneStu = {};
      localStorage.removeItem('oneStu');
    },

    storeClassObj:(state,payload)=>{
      state.classObj = payload;
      localStorage.setItem('classObj',JSON.stringify(payload))
    },

    storeOneLiveLesson:(state,payload)=>{
      state.oneLiveLesson = payload;
      // Vue.set(state,'oneLiveLesson',payload)
      localStorage.setItem('oneLiveLesson',JSON.stringify(payload))
    },

    storeKeepAlivekeepAliveArr:(state,payload)=>{
      state.keepAliveArr.push(payload);
      // Vue.set(state,'oneLiveLesson',payload)
      localStorage.setItem('keepAliveArr',state.keepAliveArr)
    },
  },

  actions: {
    saveVideoInfo(store, payload) {
      store.commit('saveVideoInfo',payload);
    },
    removeVideoInfo(store){
      store.commit('removeVideoInfo');
    },

    saveCourseInfo(store, payload) {
      store.commit('saveCourseInfo',payload);
    },
    removeCourseInfo(store){
      store.commit('removeCourseInfo');
    },

    storeUserInfo:(store,payload)=>{
      store.commit('storeUserInfo',payload)
    },
    delUserInfo:(store,payload)=>{
      store.commit('delUserInfo',payload)
    },
    storeOneClass:(store,payload)=>{
      store.commit('storeOneClass',payload)
    },

    storeOneClassTime:(store,payload)=>{
      store.commit('storeOneClassTime',payload)
    },
    storeOneStu:(store,payload)=>{
      store.commit('storeOneStu',payload)
    },
    storeOneStaff:(store,payload)=>{
      store.commit('storeOneStaff',payload)
    },
    delOneStaff:(store,payload)=>{
      store.commit('delOneStaff',payload)
    },
    delOneClass:(store,payload)=>{
      store.commit('delOneClass',payload)
    },
    delOneStu:(store,payload)=>{
      store.commit('delOneStu',payload)
    },

    storeClassObj:(store,payload)=>{
      store.commit('storeClassObj',payload)
    },

    storeOneLiveLesson:(store,payload)=>{
      store.commit('storeOneLiveLesson',payload)
    },

    storeKeepAlive:(store,payload)=>{
      store.commit('storeKeepAlive',payload)
    },

  },

  modules: {},
});
