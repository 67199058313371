<template>
<!-- 一个弹窗外壳 里面可以插入各种个性化的内容-->
<el-dialog
  :visible.sync="isDgShow"
  :before-close="resetForm"
  :width="dgWidth"
  :append-to-body="appendToBody"
  :close-on-click-modal="closeOnClickModal"
  :close-on-press-escape="closeOnClickModal"
  top="0"
  custom-class="dg_title_css dg_wrap"
>
  <p class="dg_title color3" slot="title">{{title}}</p>
  <div class="slot_box">
    <slot></slot>
  </div>

</el-dialog>
</template>
<script>
export default {
  props:{
    appendToBody:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      isDgShow:false,
      title:'',
      dgWidth:'675px',
      closeOnClickModal:true,

    }
  },

  methods:{
    show(config){
      if(config&&config.title){
        this.title = config.title;
      }
      if(config&&config.dgWidth){
        this.dgWidth = config.dgWidth;
      }
      if(config&&config.closeOnClickModal==='0'){
        this.closeOnClickModal = false;
      }

      this.isDgShow = true;
      this.$emit('dgShow')
    },

    resetForm(){
      this.isDgShow = false;
      this.$emit('dgClose')
    },
    
    hide(){
      this.isDgShow = false;
    }
  }

}
</script>
<style lang="less" scoped>

</style>
<style lang="less">
.dg_wrap{
  .el-dialog__body{
    padding:0;
  }
}

</style>

