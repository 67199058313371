<template>
  <el-dialog
    :visible.sync="isDgShow"
    :before-close="hide"
    width="535px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="0"
    :append-to-body="false"
    v-loading="loading"
    custom-class="dg_title_css batch_upload"
  >
    <p class="dg_title" slot="title">{{title||'批量导入'}}</p>

    <!-- label-width="20px" 可以当margin-left用 -->
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      style="width:495px"
    >
      <el-form-item>
        <!-- 答卷上传允许上传多个图片 -->
        <div v-if="this.usedBy == 'UploadStuAttachDialog'" class="upload_wrap">
          <el-upload
            style="display:inline-block;margin:0 10px;"
            ref="upload"
            limit="10"
            :on-exceed="showLimitTip"
            class="upload-demo"
            action="suibian"
            :accept="fileAccept"
            :on-change="fileChangeOrRemove"
            :on-remove="fileChangeOrRemove"
            :http-request="batchUpload"
          >
          <!-- .xls,.xlsx el-icon-upload  style="font-size:35px;color:#3870F6;"-->
            <i class="iconfont icon-shangchuan1 upload_icon_bg_cicle"></i>
            <br/>
            <el-button
              style="padding:7px 15px;"
              size="small"
              type="text"
              class="color9 ft16"
              >上传文件</el-button>
          </el-upload>
        </div>
        <!-- 其他上传仅允许上传1个图片 -->
        <div v-else>
          <div v-if="!Object.keys(fileObj).length">
            <el-upload
              v-if="!Object.keys(fileObj).length"
              style="display:inline-block;margin:0 10px;"
              ref="upload"
              limit="1"
              :on-exceed="showLimitTip"
              class="upload-demo"
              action="suibian"
              :accept="fileAccept"
              :on-change="fileChangeOrRemove"
              :on-remove="fileChangeOrRemove"
              :http-request="batchUpload"
            >
              <!-- .xls,.xlsx el-icon-upload  style="font-size:35px;color:#3870F6;"-->
              <i class="iconfont icon-shangchuan1 upload_icon_bg_cicle"></i>
              <br/>
              <el-button
                style="padding:7px 15px;"
                size="small"
                type="text"
                class="color9 ft16"
              >上传文件</el-button>
            </el-upload>
          </div>
          <div v-else class="upload_res_wrap">
            <div v-if="uploadRes.result" class="res_flex tc wh100">
              <span class="upload_res_img el-icon-success" style="font-size:45px;"></span>
              <p class="ft16 color3" style="line-height:1;">{{uploadRes.tip}}</p>
            </div>

            <p class="file_text ft16" v-else>
              <span>
                <span :class="['iconfont',getFileIcon()||'icon-word']" style="color:#a3c3f6;margin-right:9px;"></span>
                <span class="">{{fileObj.name||'898989898'}}</span>
              </span>

              <span class="el-icon-delete del_icon_css pointer" @click="removeFile" ></span>
            </p>
          </div>
        </div>
      </el-form-item>
      
      <el-checkbox v-if="this.usedBy == 'ExamPaperUploader'" v-model="isSyncExamPaper" style="margin:8px 0 16px 0;color: #66b1ff">导入试题同时生成试卷</el-checkbox>
      
      <div>
      <el-button
        v-if="downBtnShow && this.usedBy != 'ExamPaperUploader'"
        icon="el-icon-download"
        type="primary"
        @click="downloadTmp"
        :disabled="loading0"
        :loading="loading0"
        style="margin-bottom:20px;"
      >下载导入模板</el-button>

      <el-button
        v-if="downBtnShow && this.usedBy == 'ExamPaperUploader'"
        icon="el-icon-download"
        type="primary"
        @click="downloadPaperDesc"
        :disabled="loading0"
        :loading="loading0"
        style="margin-bottom:20px;"
      >下载试题导入说明</el-button>

      <el-button
        v-if="downBtnShow && this.usedBy == 'ExamPaperUploader'"
        icon="el-icon-download"
        type="primary"
        @click="downloadPaperExample"
        :disabled="loading0"
        :loading="loading0"
        style="margin-bottom:20px;"
      >下载试卷导入样例</el-button>
    </div>

      <el-form-item >
        <div v-html="tipsHtml"></div>
      </el-form-item>
    </el-form>

    <p slot="footer" class="dialog_footer tc">
      <template v-if="uploadRes.result">
        <el-button
          type="primary"
          @click="upload"
          :disabled="loading"
          :loading="loading"
        >{{'确定上传'}}</el-button>

        <el-button @click="hide">取 消</el-button>
      </template>
      <el-button
        v-else
        type="primary"
        @click="hide"
        :disabled="loading"
        :loading="loading"
      >关闭</el-button>

    </p>

  </el-dialog>
</template>

<script>
export default {
  props:{
    title:String,
    downBtnShow:{
      type:Boolean,
      default:true,
    },
    uploadTipType:{
      type:Number,
      default:0,
    },
    usedBy:String,
    fileAccept:String,
    classId:String
  },

  data() {
    return {
      loading: false,
      isDgShow: false,
      isSyncExamPaper: false,   // 上传试题时是否同步生成试卷
      formData: {
        UploadDocName:'',
      },
      fileList: [],
      fileObj:{}, //{a:1,bb:1},
      uploadRes:{
        result:'',
        tip:''
      },
      tips0:`
        <p class="ft18 color3">导入须知:</p>
        <p class="color9">
          1. 需要下载模板，按照模板格式内容上传即可 <br />
          2. 请认真阅读表头内需注意的问题；<br />
          3. 一次最大导入10M 大小以内。<br />
          4、表格内最多仅能支持 500 行；<br />
          5、仅支持 .xlsx 后缀文件格式。
        </p>`,
      tips1:`
        <p class="ft18 color3">导入须知:</p>
        <p class="color9">
          1. 文件上传大小 < 10M；<br />
          2. PDF文件请使用Office转换；<br />
          3. 开课时间后，上传课件入口将关闭；<br />
          4. 请提前12小时上传课件，避免影响上课使用。
        </p>`,
      tips2:`
        <p class="ft18 color3">导入须知:</p>
        <p class="color9">
          1. 请下载参考模板，务必按照模板格式补充内容 <br />
          2. 模板中的【】内容为关键词，不可修改<br />
          3. 单次导入文件大小限制10MB 以内。<br />
          4、单次导入文件内试题数量最多100 题；<br />
          5、仅支持 .doc .docx 后缀文件格式。
        </p>`, 
      tips3:`
        <p class="ft18 color3">上传说明:</p>
        <p class="red">
          1. 特别注意：如存在多个答卷图片，必须1次完成提交；后一次的提交会完全覆盖前一次的提交内容；
        </p>
        <p class="color9">
          2. 一次导入最多10张图片 <br />
          3、仅支持 .png .jpg 后缀文件格式。
        </p>`,    
      tipsHtml:null,
      dataIn:{},

    };
  },

  created(){
    //console.log('UploadStuAttachDialog created:', this.usedBy);
  },

  methods: {
    show(obj){
      //console.log('UploadStuAttachDialog show:', this.usedBy);
      if(this.usedBy=='liveCourseUpload'){
        this.dataIn = obj;
        console.log(obj)
      }
      else if(this.usedBy == 'ExamPaperUploader'){
        console.log(this.usedBy);
      }else if(this.usedBy == 'UploadStuAttachDialog'){
        this.dataIn = obj;
      }
      this.isDgShow = true;
      this.dataReset();
    },

    dataReset(){
      this.$refs.upload.clearFiles();
      this.fileObj = {};
      this.formData = {UploadDocName:'',};
      this.uploadRes={
        result:'',
        tip:''
      }
    },

    upload() {
      if(!this.formData.UploadDocName){
        this.$tipWarn('您还没有上传文件，请上传文件后再提交')
        return false;
      }

      let subFn = ()=>{
        let data = {};
        let apiName;
        if(this.usedBy == 'liveCourseUpload'){
          data = {
            ...this.dataIn,
            LectureID:this.formData.UploadDocName
          }
          apiName= 'liveAddLectureDoc';
        }else if(this.usedBy == 'ExamPaperUploader'){
          data = {
            SyncExamPaper: this.isSyncExamPaper,
            UploadDocName:this.formData.UploadDocName
          }
          apiName= 'ExamUploadExamQuestion';
        }else if(this.usedBy == 'UploadStuAttachDialog'){
          data = {
            StuID: this.dataIn.StuID,
            ExamPaperID: this.dataIn.examPaperId,
            ExamRecordID: this.dataIn.examRecordId,
            UploadDocName:this.formData.UploadDocName
          }
          apiName= 'ExamUploadStuAttach';
        }else{
          data = {
            UploadDocName:this.formData.UploadDocName
          }
          apiName= 'ImportStudent';
          if(this.classId){
            data.ClassID = this.classId;
          }
        }

        this.loading = true;
        this.$api[apiName](data).then(res=>{
          this.loading = false;
          this.$tipOk(res.ErrMsg)
          this.$emit('uploaded')
          this.hide();
          this.dataReset();
        }).catch(err=>{
          // 批量上传学生 报错
          this.loading = false;
          if(err.ErrCode==8){
            this.dataReset();
          }
        })
      }
      subFn();
    },

    getFileIcon(){
      let douTypes = [
        '.xls,.xlsx',
        '.doc,.docx',
        'pdf',
      ]
      if(douTypes[0].indexOf(this.fileType)>-1){
        return 'icon-excel'
      }else if(douTypes[1].indexOf(this.fileType)>-1){
        return 'icon-word'
      }

    },

    /* 文件上传处理 */
    batchUpload(fileObj) {
      //console.log('batchUpload:', fileObj);
      const file = fileObj.file;
      const {name, size} = file;
      if (size / 1024 / 1024 > 10) {
        this.$tipWarn('文件大小不超过10M')
        return;
      }
      let fileType = name.split('.').pop();
      this.fileType = fileType;

      if (this.fileAccept.indexOf(fileType)>-1) {
        this.fileObj = file;

        if(this.usedBy=='liveCourseUpload'){
          this.$api.uploadFileFn('USR_RES_CLSDOC',file).then(res=>{
            this.formData.UploadDocName = res;
            this.uploadRes = {
              result:'ok',
              tip:`上传 ${res} 成功`
            }
          }).catch(err=>{
            console.log(err,'er.err0999')
          })
        }
        else if(this.usedBy=='ExamPaperUploader'){
          this.$api.uploadPicFn('USR_RES_EXAMDOC',file).then(res=>{
            this.formData.UploadDocName = res;
            this.uploadRes = {
              result:'ok',
              tip:`上传 ${res} 成功`
            }
          }).catch(err=>{
            console.log(err,'er.err0999')
          })
        }
        else if(this.usedBy=='UploadStuAttachDialog'){
          this.$api.uploadPicFn('USR_RES_EXAMPIC', file).then(res=>{
            console.log('upload success:', this.formData.UploadDocName, res);
            this.formData.UploadDocName = this.formData.UploadDocName==''? res : (this.formData.UploadDocName + ',' + res);
            this.uploadRes = {
              result:'ok',
              tip:`上传 ${res} 成功`
            }
          }).catch(err=>{
            console.log(err,'er.err0999')
          })
        }
        else{
          this.$api.uploadPicFn('USR_RES_AGTSTUXLS',file).then(res=>{
            this.formData.UploadDocName = res;
            this.uploadRes = {
              result:'ok',
              tip:`上传 ${res} 成功`
            }
          }).catch(err=>{
            console.log(err,'er.err0999')
          })
        }
      } else {
        this.$tipWarn('请上传正确的文件类型（.doc .docx)');
      }
    },

    //下载模板
    downloadTmp(){
      // let origin = 'http://zhuovip.ruiitg.com'
      let origin = location.origin
      if(this.usedBy == 'ExamPaperUploader'){
        window.location.href = origin+'/static/学员导入样例2.xlsx';
      }else{
        window.location.href = origin+'/static/学员导入样例.xlsx';
      }
      
    },

    downloadPaperDesc(){
      let origin = location.origin;
      window.location.href = origin+'/static/卓学云导入试卷格式要求规范.docx';
    },

    downloadPaperExample(){
      let origin = location.origin;
      window.location.href = origin+'/static/试题导入模板2022.docx';
    },

    removeFile(){
      this.fileObj = {};
      // this.ruleForm.DocFileName = '';
    },

    showLimitTip(){
      this.$message('只能上传一个文件')
    },

    fileChangeOrRemove(file, fileList){
      //console.log('fileChangeOrRemove:', file, fileList);
      if(!fileList.length){
        this.fileObj = {
        }
      }
    },

    handleNull(rule, val, cb) {
      if (!val && val !== null) {
        cb(new Error(rule.messge));
      } else {
        cb();
      }
    },

    //取消提交
    hide() {
      this.isDgShow = false;
      // setTimeout(() => {
      //   this.$refs.upload.clearFiles();
      // }, 190);
    },

    //上传之前的文件格式校验
    // beforeUpload(file) {
    //   let accept = '.jpg,.jpeg';
    //   // return this.$comBeforeUpload(file, accept, [15, 180]);
    // },
    //上传图片的被调
    beCalledFn(res, file, fileList) {
      this.formData.fileList = [].concat(fileList);
    },
    //删除照片回调
    handleRemove(file, fileList) {
      this.formData.fileList = [].concat(fileList);
    },
    //超出文件个数的回调
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 1 个文件，已选择了 1 个文件`);
    // },
  },

  mounted() {
    console.log('UploadStuAttachDialog mounted:', this.usedBy);
    if(this.usedBy == 'UploadStuAttachDialog'){
      this.tipsHtml = this['tips3'];
    }else{
      this.tipsHtml = this['tips'+this.uploadTipType];
    }
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/self-theme-color.less';
.dialog_footer{
  padding-top:0;
}
.upload_res_wrap{
  width: 100%;
  height: 180px;
  padding: 25px 20px;
  background-color:#f9fbfc;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  // display: flex!important;
  // justify-content: center;
  // align-items:center;
  .res_flex{
    display: flex!important;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
}
.upload_res_img{
  display:inline-block;
  width:45px;
  height:45px;
  margin-bottom:12px;
  border-radius: 50%;
  color:@green2;

  // background:url('~@/assets/no_content.png') left top no-repeat;
  // background-size:100%;
}
.file_text{
  width: 100%;
  transition: all .5s cubic-bezier(.55,0,.1,1);
  transition: color .3s;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .del_icon_css{
    // float:right;
    // margin-top:13px ;/
    // color:red;
    // cursor: pointer;
  }
}
.upload-demo{
  margin:0!important;
  width: 100%;
  height: 180px;
  background-color:#F9FBFC;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  display: flex!important;
  justify-content: center;
  align-items:center;
  .el-upload{
    display: block;
  }
}
</style>
<style lang="less">
.batch_upload{
  .el-dialog__body{
    padding-bottom:0;
  }
}
</style>
