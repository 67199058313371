<template>
  <el-dialog
    :visible.sync="isDgShow"
    :before-close="cancel"
    :width="'470px'"
    top="0"
    :modal="false"
    custom-class="dg_title_css tip_dg"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <p class="dg_title" slot="title">{{config.title||''}}</p>
    <p class="tc" style="margin-bottom:42px;line-height:24px;">
      <template
        v-for="(line,i) in config.tip.split('\n')"
      >
        <span :key="i">{{line}}</span> <br :key="i+20" />
      </template>
      <el-checkbox v-if="this.config.useCheckbox == true" v-model="boxSelected">{{ this.config.checkBoxName }}</el-checkbox>
    </p>
    <p class="tc">
      <el-button style="font-size:14px;" @click="cancel">取消</el-button>
      <el-button style="font-size:14px;margin-left:20px;" type="primary" @click="submit">确定</el-button>
    </p>

  </el-dialog>
</template>

<script>
export default {

  data() {
    return {
      isDgShow:false,
      config:{
        title:'',
        tip:''
      },
      useCheckbox: false,
      boxSelected: false,
    };
  },

  methods: {

    show(obj){
      this.boxSelected = false;
      this.config = obj;
      this.isDgShow = true;
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },

    //确认提交
    submit() {
      var boxSelected = this.boxSelected;
      this.config = {...this.config, boxSelected};
      this.$emit('publicChange', this.config);
      this.isDgShow = false;
      this.config = {
        title:'',
        tip:''
      };
    },

    //取消提交
    cancel() {
      this.isDgShow = false;
      this.config = {
        title:'',
        tip:''
      };
    },
  },

  mounted() {},
};
</script>

<style lang="less">
.tip_dg{
  .el-dialog__body{
    padding: 20px;
  }
  // .el-dialog__body{
  //   padding:20px!important;
  // }
}
</style>


