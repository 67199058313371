<template>
  <div>
    <el-table
      ref="table"
      :data="tableData"
      :height="listHeight"
      :header-cell-style="{ height: '50px', ...headerCellStyle }"
      @selection-change="handleSelectionChange"
      @select="selectionSelect"
      @select-all="selectAll"
      size="middle"
      empty-text="暂无数据"

    >
      <!-- padding-left 无效，加一个空列解决 -->
      <el-table-column width="17"></el-table-column>
      <template v-for="(item, i) in columns">
        <el-table-column
          v-if="item.type === 'selection'"
          :key="i"
          type="selection"
          width="55"
        />
        <el-table-column
          v-else-if="item.type == 'slot'"
          :key="i"
          :prop="item.propName"
          :label="item.title"
          :width="item.width"
          :class-name="item.slotName"
          align="left"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            <slot
              :name="item.slotName"
              :scope="scope.row"
              :rowIndex="scope.$index"
              :propName="item.propName"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'operate'"
          :key="i"
          :prop="item.propName"
          :label="item.title"
          :width="item.width"
          align="left"
          :min-width="item.minWidth"
          :fixed="item.fixed"
        >
          <template slot-scope="scope">
            <div v-if="item.type === 'operate'">
              <template v-for="(operate, btnIndex) in item.operateList">
                <el-dropdown
                  v-if="operate.emitKey == 'moreOper'"
                  :key="btnIndex"
                >
                  <span
                    class="ft14 el-dropdown-link"
                    style="margin-left:10px;color:#3870F6;"
                  >
                    更多
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(btn, i) in operate.moreBtns"
                      :key="i"
                      @click.native="
                        handleClick(
                          btn,
                          Object.assign({}, scope.row, {
                            rowIndex: scope.$index,
                          }),
                        )
                      "
                      >{{
                        btn.emitKey == 'beInvalid' && scope.row.Status == 1
                          ? '转为正常学生'
                          : btn.label
                      }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <!-- 按钮文案 : 0 讲次管理   1 新增讲次  不过现在不用了 -->
                <el-button
                  v-else-if="operate.emitKey == 'variable'"
                  :key="btnIndex"
                  type="text"
                  size="medium"
                  :class="['list_btn', operate.btnColor]"
                  @click="
                    handleClick(
                      operate.fn(0),
                      Object.assign({}, scope.row, { rowIndex: scope.$index }),
                    )
                  "
                  >{{ operate.fn(0).title || scope.row[item.propName] }}
                </el-button>

                <el-button
                  v-else-if="
                    operate.emitKey != 'del' && operate.emitKey != 'variable'
                  "
                  :key="btnIndex"
                  type="text"
                  size="medium"
                  :class="['list_btn', operate.btnColor]"
                  :disabled="scope.row.ManualChecked==1&&operate.title=='补充答卷'?true:false"
                  @click="handleClick(operate, Object.assign({}, scope.row, { rowIndex: scope.row }),)"
                  >{{ operate.title || scope.row[item.propName] }}
                </el-button>

                <!-- 直播的讲次管理列表 只有最后一个可以删除，其他的不显示删除 -->
                <!-- ROW_NUMBER是数据项在整个有效数据集的序号，与分页无关，注意本字段有框架添加。 -->
                <span
                  v-else-if="['/liveClassTimesManage','/classTimesManage'].includes($route.path)&&scope.row.ROW_NUMBER!= scope.row.total"
                  :key="btnIndex"
                >
                </span>

                <el-popconfirm
                  v-else
                  :key="btnIndex"
                  title="确定删除吗？"
                  style="margin-left:10px;margin-right:10px;"
                  @confirm="
                    handleClick(
                      operate,
                      Object.assign({}, scope.row, { rowIndex: scope.row }),
                    )
                  "
                >
                  <el-button type="text" size="medium" slot="reference"
                    >删除</el-button
                  >
                </el-popconfirm>
              </template>
            </div>
            <!-- 纯文字 -->
            <span
              v-else-if="item.type === 'wordbreak'"
              class="word-nowrap"
              @click="changeWordBreak($event)"
              >{{ scope.row[column.props] }}</span
            >
            <span v-else>{{ scope.row[column.props] }}</span>
          </template>
        </el-table-column>
        <!-- 有的列 需要限制一行 超出之后 浮窗显示 -->
        <el-table-column
          v-else-if="item.type == 'limitOneLine'"
          :key="i"
          :prop="item.propName"
          :label="item.title"
          :class-name="item.type"
          :width="item.width"
          align="left"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            <div class="name_cell" style="width:100%;position:relative;">
              {{ getNameCellWidth() }}
              <el-popover
                v-if="scope.row.Name.length * 14 > nameCellWidth"
                placement="top-start"
                width="250"
                trigger="hover"
                :content="scope.row.Name"
              >
                <div slot="reference" class="limit_one_line">
                  {{ scope.row.Name }}
                </div>
              </el-popover>
              <span v-else>{{ scope.row.Name }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-else
          :key="i"
          :prop="item.propName"
          :label="item.title"
          :width="item.width"
          align="left"
          :min-width="item.minWidth"
        ></el-table-column>
      </template>
    </el-table>

    <div v-if="needPagination" style="margin-top:10px;text-align:right;">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :page-sizes="pageSizeList"
        :small="true"
        :total="pagination0 && pagination0.total"
        :page-size="pagination0 && pagination0.pageSize"
        :current-page="pagination0 && pagination0.current"
        @current-change="currentChange"
        @size-change="pageSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
const defaultPagination = {
  total: 0,
  pageSize: 10,
  current: 1,
};

export default {
  props: {
    columns: Array,
    headerCellStyle: Object,
    pagination: Object,
    needPagination: {
      type: Boolean,
      default: true,
    },
    tableData: Array,
    process: Function,
    tableLayHeight: String,
    showInDialogClass: {
      type: String,
      default: '',
    },

    lazy: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Function,
    },
    rowKey: {
      type: String,
      default: '',
    },
    treeProps: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      listHeight: 200,
      listMaxHeight: '',
      pagination0: defaultPagination,
      pageSizeList: [10,20,100],
    };
  },

  watch: {
    pagination: {
      handler(n, o) {
        this.pagination0 = {
          ...this.pagination0,
          ...n,
        };
        if(n.pageSize && !this.pageSizeList.includes(n.pageSize)){
          this.pageSizeList.push(n.pageSize);
        }
      },
      deep: true,
    },

    tableData: {
      handler(n, o) {
        // console.log('tableWatch')
        setTimeout(() => {
          this.calcTableHeight();
        }, 0);
      },
      deep: true,
    },

    tableLayHeight(n, o) {
      // console.log(n,o,'112')
      this.listMaxHeight = this.tableLayHeight;
      this.calcTableHeight();
    },
  },

  mounted() {
    // console.log(this.tableLayHeight,'900')
    if (this.tableLayHeight) {
      this.listMaxHeight = this.tableLayHeight;
    } else {
      this.listMaxHeight =
        document.getElementById('app').clientHeight -
        85 -
        66 -
        40 -
        50 -
        32 -
        46 -
        49 -
        57;
      // console.log(this.listMaxHeight,'234')
    }
    setTimeout(() => {
      this.pagination0 = {
        ...this.pagination0,
        ...this.pagination,
      };
      this.calcTableHeight();
    }, 100);

    window.onresize = this.calcTableHeight();
  },

  methods: {
    handleClick(action, data) {
      // console.log(action,data)
      this.$emit(`${action.emitKey}`, data);
    },

    // 点击某个选中
    handleSelectionChange(val) {
      // console.log(val)
      this.$emit('handleSelectionChange', val);
    },

    selectionSelect(selection, row) {
      this.$emit('selectionSelect', { selection, row });
    },

    selectAll(selection) {
      this.$emit('selectAll', selection);
    },

    // 全选
    handleAllSelection(val) {
      this.$emit('handleAllSelection', { val: val });
    },

    changeWordBreak(ele) {
      if (ele.target.classList.contains('word-nowrap')) {
        ele.target.classList.remove('word-nowrap');
        ele.target.classList.add('word-wrap');
      } else {
        ele.target.classList.remove('word-wrap');
        ele.target.classList.add('word-nowrap');
      }
    },

    calcTableHeight() {
      // this.listMaxHeight = document.getElementById('app').clientHeight - 85 - 66 - 40 - 50 - 32 - 46 - 49 -57;
      // table 的每行高度 加上 表头高度
      let tableTitleHeight = document.querySelector('.el-table__header-wrapper')
        .offsetHeight;
      let tableRowHeight;

      // 如果是弹窗中的表格
      if (this.showInDialogClass) {
        tableRowHeight =
          document.querySelector(
            `.${this.showInDialogClass} .el-table__body-wrapper .el-table__body`,
          ).offsetHeight +
          tableTitleHeight +
          1;
      } else {
        tableRowHeight =
          document.querySelector('.el-table__body-wrapper table').offsetHeight +
          tableTitleHeight +
          1;
        let dom = document.querySelector('#stulisttable .el-table__body-wrapper table');
        if(dom){
          let findtableHeight = dom.offsetHeight + tableTitleHeight + 1;
          tableRowHeight = findtableHeight;
        }
      }
      // console.log(tableTitleHeight,'hhhtt',tableRowHeight)
      if (!this.tableData.length) {
        tableRowHeight += 60;
      }
      // console.log(tableRowHeight,this.listMaxHeight);
      if (tableRowHeight > this.listMaxHeight) {
        this.listHeight = this.listMaxHeight;
      } else if (tableRowHeight < 200) {
        this.listHeight = 200;
      } else {
        this.listHeight = tableRowHeight;
      }
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },

    getNameCellWidth() {
      // 此处用的 应该室 td外壳的宽度，而不是插槽中 div的宽度，因为后者是后来插入的
      let dom = document.getElementsByClassName('limitOneLine')[0];
      if (dom) {
        this.nameCellWidth = dom.offsetWidth - 20;
      } else {
        this.nameCellWidth = 9999;
      }
      // console.log(document.getElementsByClassName('name_cell')[0])
      // return document.getElementsByClassName('name_cell')[0].offsetWidth;
    },

    currentChange(current) {
      this.pagination0.current = current;
      this.tableRefresh();
    },

    pageSizeChange(curSize){
      this.pagination0.pageSize = curSize;
      this.process && this.process(this.pagination0);
    },

    tableRefresh() {
      this.process && this.process(this.pagination0);
    },
  },
};
</script>

<style lang="less" scoped>
.limit_one_line {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
